import React from "react"
import BuyersGuide from "../containers/RealestatePage1/Properties/BuyersGuide"
import Headermenu from "../containers/RealestatePage1/HeaderMenuBS"
import Footer from "../containers/RealestatePage1/Footer"
import GlobalStyle from "../containers/RealestatePage1/Common/global-styles"
import Details from "../containers/RealestatePage1/Details"
import Fonts from "../containers/RealestatePage1/Common/fonts"
import "../components/layout.css"
import SEO from "../components/seo"

function propertDetails({ pageContext }) {
  return (
    <>
      <Fonts />
      <GlobalStyle />
      <main>
        <SEO
          title="Property details"
          description="Get Your Dream Home With Us"
        />
        <Headermenu home={false} />

        {/* <div>
          <h1 style={{ marginTop: 90 }}>{pageContext.detail.slug}</h1>
          <h1>{pageContext.detail.Price}</h1>
        </div> */}
        <Details details={pageContext.detail} />

        <BuyersGuide />
      </main>
      <Footer />
    </>
  )
}

export default propertDetails
