import React, { Component } from "react"
import {
  PropertiesSection,
  TopLayout,
  HeadingLayout,
  SubHeading,
  Heading,
  CustomTabList,
  SliderOuterWrapper,
  SliderWrapper,
  PropertyLayout,
  PropertyImgWrapper,
  SpecLayout,
  SizeText,
  SpecItemLayout,
  SpecIcon,
  SpecText,
  Price,
  Contact,
  Button,
  PrevImg,
  NextImg,
  NextPrev,
  CustomLink,
  PriceConverter,
  PriceLayout,
  Center,
  DetailImageHolder,
} from "../Properties/properties.style"
import { ImageHolder, AboutGalleryWrapper } from "../AboutUs/aboutus.style"
import { Container, Row, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-tabs/style/react-tabs.css"
import { StaticQuery, graphql, Link } from "gatsby"
import GatsImg from "gatsby-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Lightbox from "react-image-lightbox"

class Details extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenLightBox: false,
      lightBoxImages: [],
      photoIndex: 0,
    }
    this.openLightBox = this.openLightBox.bind(this)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }
  openLightBox(imageArray, idx) {
    const images = []
    for (const [index, value] of imageArray.SliderData.entries()) {
      console.log(index)
      images.push(value.Img.childImageSharp.fluid.src)
    }
    this.setState({
      isOpenLightBox: true,
      lightBoxImages: images,
      photoIndex: idx,
    })
  }

  render() {
    const settings = {
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    }

    return (
      <>
        <PropertiesSection id="propertiesSection">
          <Container>
            <PropertyLayout>
              <SliderOuterWrapper>
                <SliderWrapper>
                  <Slider ref={c => (this.slider = c)} {...settings}>
                    {this.props.AboutData.SliderData.map((imageObj, idx) => {
                      return (
                        <AboutGalleryWrapper>
                          <DetailImageHolder
                            onClick={this.openLightBox.bind(
                              this,
                              this.props.AboutData,
                              idx
                            )}
                          >
                            <GatsImg
                              fluid={imageObj.Img.childImageSharp.fluid}
                              alt=""
                              className="aboutUsSliderImg"
                            />
                          </DetailImageHolder>
                        </AboutGalleryWrapper>
                      )
                    })}
                  </Slider>
                </SliderWrapper>
              </SliderOuterWrapper>
              <Row className="justify-content-center">
                {" "}
                <NextPrev>
                  <Button onClick={this.previous} aria-label="Prev Button">
                    <PrevImg src={this.props.PropertiesData.PrevImg} alt="" />
                  </Button>
                  <Button onClick={this.next} aria-label="Next Button">
                    <NextImg src={this.props.PropertiesData.NextImg} alt="" />
                  </Button>
                </NextPrev>{" "}
              </Row>

              <Row className="justify-content-between">
                <Col md={8} sm={6}>
                  <SpecLayout>
                    <SizeText>{this.props.details.SizeText}</SizeText>
                    <SpecItemLayout>
                      <SpecIcon
                        src={this.props.details.SpecItemLayout[0].SpecIcon}
                        alt=""
                      />
                      <SpecText>
                        {this.props.details.SpecItemLayout[0].SpecText}
                      </SpecText>
                    </SpecItemLayout>
                    <SpecItemLayout>
                      <SpecIcon
                        src={this.props.details.SpecItemLayout[1].SpecIcon}
                        alt=""
                      />
                      <SpecText>
                        {this.props.details.SpecItemLayout[1].SpecText}
                      </SpecText>
                    </SpecItemLayout>
                    <SpecItemLayout>
                      <SpecIcon
                        src={this.props.details.SpecItemLayout[2].SpecIcon}
                        alt=""
                      />
                      <SpecText>
                        {this.props.details.SpecItemLayout[2].SpecText}
                      </SpecText>
                    </SpecItemLayout>
                    <SpecItemLayout>
                      <SpecIcon
                        src={this.props.details.SpecItemLayout[3].SpecIcon}
                        alt=""
                      />
                      <SpecText>
                        {this.props.details.SpecItemLayout[3].SpecText}
                      </SpecText>
                    </SpecItemLayout>
                  </SpecLayout>
                </Col>
                <Col md={4} sm={6}>
                  <SpecLayout>
                    <SpecItemLayout>
                      <PriceLayout>
                        <Price>{this.props.details.Price}</Price>
                        <div className="price-converter">
                          <h4>D8,000,000</h4>
                          <h4>€142,0000</h4>
                        </div>
                      </PriceLayout>
                    </SpecItemLayout>
                  </SpecLayout>
                </Col>
              </Row>
              <h4 style={{ marginTop: 20 }}>Description</h4>
              <hr />
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum
              </p>

              <CustomLink to="/properties" style={{ marginTop: 30 }}>
                {" "}
                See More Properties
              </CustomLink>
            </PropertyLayout>
          </Container>
        </PropertiesSection>

        {this.state.isOpenLightBox && (
          <Lightbox
            mainSrc={this.state.lightBoxImages[this.state.photoIndex]}
            nextSrc={
              this.state.lightBoxImages[
                (this.state.photoIndex + 1) % this.state.lightBoxImages.length
              ]
            }
            prevSrc={
              this.state.lightBoxImages[
                (this.state.photoIndex + this.state.lightBoxImages.length - 1) %
                  this.state.lightBoxImages.length
              ]
            }
            onCloseRequest={() => this.setState({ isOpenLightBox: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex +
                    this.state.lightBoxImages.length -
                    1) %
                  this.state.lightBoxImages.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex + 1) %
                  this.state.lightBoxImages.length,
              })
            }
          />
        )}
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        realestatePage1Json {
          Properties {
            PrevImg
            NextImg
          }
          DetailSamples {
            SliderData {
              Img {
                childImageSharp {
                  fluid(quality: 100) {
                    aspectRatio
                    sizes
                    src
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Details
        PropertiesData={data.realestatePage1Json.Properties}
        AboutData={data.realestatePage1Json.DetailSamples}
        {...props}
      />
    )}
  />
)
